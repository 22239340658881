import { gsap } from 'gsap';

export default () => {
  const p = ModuleTab.prototype;

  function ModuleTab(){
    this._$tab = App.elems.$CONTENT.find('[data-tab]');
    this._$tabs = [];

    this._props = {
      menu: '[data-tab-menu]',
      main: '[data-tab-main]'
    };

    this.init();
  }

  p.init = function(){
    this.handleEvents();
  };

  p.handleEvents = function(){
    Observer.on(App.events.LOADED, this.onLoaded.bind(this));
  };

  p.onLoaded = function(page){
    if(this._$tab.length) this._$tab.each(this.set.bind(this));
  };

  p.set = function(idx, val){
    this._$tabs[idx] = {menu: null, main: null};
    this._$tabs[idx].menu = this._$tab.eq(idx).find(this._props.menu);
    this._$tabs[idx].main = this._$tab.eq(idx).find(this._props.main);


    this._$tabs[idx].menu.eq(0).addClass(App.state.ACTIVE);
    this._$tabs[idx].main.eq(0).addClass(App.state.ACTIVE);
    gsap.set(this._$tabs[idx].main.eq(0), {y: 0, opacity: 1});

    this._$tabs[idx].menu.on(App.events.CLICK, {idx: idx}, this.onClick.bind(this));
  };

  p.onClick = function(e){
    e.preventDefault();

    this._$tabs[e.data.idx].menu.removeClass(App.state.ACTIVE);
    this._$tabs[e.data.idx].main.removeClass(App.state.ACTIVE);

    const num = this._$tabs[e.data.idx].menu.index(e.currentTarget);
    this._$tabs[e.data.idx].menu.eq(num).addClass(App.state.ACTIVE);
    this._$tabs[e.data.idx].main.eq(num).addClass(App.state.ACTIVE);
    gsap.fromTo(this._$tabs[e.data.idx].main.eq(num), {
      y: 10,
      opacity: 0
    }, {
      duration: 0.8,
      ease: 'power2.inOut',
      y: 0,
      opacity: 1
    });
  };

  new ModuleTab();
};