import { gsap } from 'gsap';
import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin( $ );

export default () => {
  const p = EventsLoad.prototype;

  function EventsLoad(){
    this._props = {
      parcent: 0,
      page: ''
    };

    this.init();
  }

  p.init = function(){
    this._props.page = App.elems.$BODY.attr('data-page');
    this.handleEvents();
  };

  p.handleEvents = function(){
    App.elems.$BODY.imagesLoaded({background: true}).done(this.done.bind(this));
  };

  p.done = function(ins){
    Observer.trigger(App.events.LOADED, this._props.page);

    // Commenting out the gsap.to method to remove the fade-in effect
    // gsap.to(App.elems.$PAGE, {
    //   duration: 0.1,
    //   ease: 'power2.out',
    //   opacity: 1,
    //   onComplete: this.complete.bind(this)
    // });

    this.complete.bind(this)();  // Call the complete method directly
  };

  p.complete = function(){
    Observer.trigger(App.events.OPENED, this._props.page);
  };

  new EventsLoad();
};
