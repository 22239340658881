import { gsap } from 'gsap';

export default () => {
  const p = EventsHeader.prototype;

  function EventsHeader(){
    this._$header = {
      logo: App.elems.$HEADER.find('[data-header-logo]'),
      navi: App.elems.$HEADER.find('[data-header-navi]'),
      item: App.elems.$NAVI.find('[data-header-item]'),
      main: App.elems.$NAVI.find('[data-navi-main]')
    };
    this._$toggle = App.elems.$HEADER.find('[data-navi-toggle]');
    this._$layer = App.elems.$NAVI.find('[data-navi-layer]');
    this._$outer = App.elems.$NAVI.find('[data-navi-outer]');
    this._$main = this._$header.main.find('a');
    this._$help = App.elems.$NAVI.find('[data-navi-help]');

    this._props = {
      flag: true
    };

    this._scroll = {
      y: App.elems.$DOC.scrollTop(),
      old: App.elems.$DOC.scrollTop(),
      calc: 0,
      header: this._$header.logo.height()
    };

    this.init();
  }

  p.init = function(){
    this.toggleClass(this._scroll);
    this.handleEvents();
  };

  p.handleEvents = function(){
    Observer.on(App.events.MODECHANGE, this.reset.bind(this));
    Observer.on(App.events.OPENED, this.onOpened.bind(this));
    Observer.on(App.events.SCROLL, this.onScroll.bind(this));

    Observer.on(App.events.OPEN, this.open.bind(this));
    Observer.on(App.events.CLOSE, this.close.bind(this));

    this._$toggle.on(App.events.CLICK, this.onClick.bind(this));
  };

  // p.onOpened = function(){
  //   gsap.to(this._$header.logo, {
  //     delay: 0.2,
  //     duration: 1.2,
  //     ease: 'power2.out',
  //     opacity: 1,
  //     y: 0
  //   });
  //   gsap.to(this._$header.item, {
  //     delay: 0.2,
  //     stagger: 0.05,
  //     duration: 1.2,
  //     ease: 'back.out(1.4)',
  //     opacity: 1,
  //     y: 0
  //   });
  //   gsap.to(this._$header.navi, {
  //     delay: 0.4,
  //     duration: 1.2,
  //     ease: 'power2.out',
  //     opacity: 1
  //   });
  // };

  p.onOpened = function(){
    gsap.to(this._$header.logo, {
      delay: 0,
      duration: 0,
      ease: 'power2.out',
      opacity: 1,
      y: 0
    });
    gsap.to(this._$header.item, {
      delay: 0,
      stagger: 0,
      duration: 0,
      opacity: 1,
      y: 0
    });
    gsap.to(this._$header.navi, {
      delay: 0,
      duration: 0,
      ease: 'power2.out',
      opacity: 1
    });
  };

  p.onScroll = function(e){
    if(App.mode.device === 'isPC') this.toggleClass(e);
    
    this._props.calc = e.y - this._scroll.old;
    if(!App.elems.$NAVI.hasClass(App.state.ACTIVE) && e.y > this._scroll.header){
      if(e.y > this._scroll.old && this._props.calc > 10){
        this.slideOut();
      }else if(e.y < this._scroll.old && this._props.calc < -10){
        this.slideIn();
      }
    }
    this._scroll.old = e.y;
  };

  p.toggleClass = function(e){
    if(e.y > 0){
      App.elems.$HEADER.addClass(App.state.HIDDEN);
    }else{
      App.elems.$HEADER.removeClass(App.state.HIDDEN);
    }
  };

  p.slideIn = function(){
    gsap.to([this._$header.logo, this._$header.main, this._$header.navi], {
      stagger: 0.05,
      duration: 0.8,
      ease: 'power2.out',
      opacity: 1,
      y: 0
    });
  };

  p.slideOut = function(){
    gsap.to([this._$header.logo, this._$header.main, this._$header.navi], {
      duration: 1,
      ease: 'power2.out',
      opacity: 0,
      y: -this._scroll.header
    });
  };

  p.onClick = function(e){
    e.preventDefault();

    if(this._props.flag){
      this._props.flag = false;
      if(App.elems.$NAVI.hasClass(App.state.ACTIVE)){
        Observer.trigger(App.events.CLOSE);
      }else{
        Observer.trigger(App.events.OPEN);
      }
    }
  };

  p.open = function(){
    this._$toggle.addClass(App.state.ACTIVE);
    App.elems.$NAVI.addClass(App.state.ACTIVE);

    gsap.fromTo(this._$layer, {
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)'
    }, {
      duration: 1,
      ease: 'power3.out',
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      onComplete: this.completeOpen.bind(this)
    });
    gsap.fromTo(this._$main, {
      y: 10,
      opacity: 0
    }, {
      delay: 0.1,
      stagger: 0.04,
      duration: 0.8,
      ease: 'back.out(1.7)',
      y: 0,
      opacity: 1
    });
    gsap.fromTo(this._$help, {
      y: 10,
      opacity: 0
    }, {
      delay: 0.4,
      duration: 0.8,
      ease: 'back.out(1.7)',
      y: 0,
      opacity: 1
    });
  };

  p.completeOpen = function(){
    this._props.flag = true;
  };

  p.close = function(){
    this._$toggle.removeClass(App.state.ACTIVE);

    gsap.to(this._$layer, {
      duration: 0.8,
      ease: 'power3.inOut',
      clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0 100%)',
      onComplete: this.completeClose.bind(this)
    });
    gsap.to(this._$main, {
      duration: 0.4,
      ease: 'power2.out',
      y: -10,
      opacity: 0
    });
    gsap.to(this._$help, {
      duration: 0.6,
      ease: 'power2.out',
      y: -10,
      opacity: 0
    });
  };

  p.completeClose = function(){
    App.elems.$NAVI.removeClass(App.state.ACTIVE);
    this._props.flag = true;
  };

  p.reset = function(){
    this._scroll.header = this._$header.logo.height();
    App.elems.$NAVI.removeClass(App.state.ACTIVE);
    this._$toggle.removeClass(App.state.ACTIVE);
    
    gsap.set(this._$layer, {clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)'});
    gsap.set(this._$main, {y: 0, opacity: 1});
    gsap.set(this._$help, {y: 0, opacity: 1});
  };

  new EventsHeader();
};