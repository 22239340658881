export default () => {
  const p = ModuleScrollFollow.prototype;

  function ModuleScrollFollow(){
    this._$elem = $('#follow');

    this._props = {
      y: App.elems.$DOC.scrollTop(),
      h: App.elems.$WIN.innerHeight(),
      footer: App.elems.$FOOTER.offset().top
    };

    this.init();
  }

  p.init = function(){
    if(this._$elem.length){
      this.onScroll(this._props);
      this.handleEvents();
    }
  };

  p.handleEvents = function(){
    Observer.on(App.events.SCROLL, this.onScroll.bind(this));
    Observer.on(App.events.RESIZE, this.onResize.bind(this));
  };

  p.onScroll = function(e){
    if(e.y < 10 || (e.y + this._props.h) > this._props.footer){
      this._$elem.removeClass(App.state.ACTIVE);
    }else{
      this._$elem.addClass(App.state.ACTIVE);
    }
  };

  p.onResize = function(e){
    this._props.h = e.h;
    this._props.footer = App.elems.$FOOTER.offset().top;
  };

  new ModuleScrollFollow();
};