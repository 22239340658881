import Swiper from 'swiper/bundle';

export default () => {
  const p = ModuleLoopSlider.prototype;

  function ModuleLoopSlider(){
    this._$slider = App.elems.$CONTENT.find('[data-loop-slider]');

    this._props = {
      slider: []
    };

    this.init();
  }

  p.init = function(){
    this.handleEvents();
  };

  p.handleEvents = function(){
    Observer.on(App.events.LOADED, this.onLoaded.bind(this));
  };

  p.onLoaded = function(page){
    if(this._$slider.length) this._$slider.each(this.set.bind(this));
  };

  p.set = function(idx, val){
    this._props.slider[idx] = new Swiper(val, {
      loop: true,
      slidesPerView: 'auto',
      speed: 20000,
      autoplay: {
        delay: 0,
        disableOnInteraction: false
      }
    });
  };

  new ModuleLoopSlider();
};