window.App = window.App || {};

export default () => {
  const p = Initialize.prototype;

  function Initialize() {
    this.init();
  }

  p.init = function() {
    App.events = {
      LOAD: 'load',
      MODECHANGE: 'modechange',
      SCROLL: 'scroll',
      RESIZE: 'resize',
      CLICK: 'click',
      LOADED: 'loaded',
      OPENED: 'opened',
      OPEN: 'open_navi',
      CLOSE: 'close_navi'
    };

    App.elems = {
      $DOC: $(document),
      $WIN: $(window),
      $HTML: $('html'),
      $HEAD: $('head'),
      $BODY: $('body'),
      $HTML_BODY: $('html, body'),
      $PAGE: $('#page'),
      $HEADER: $('#header'),
      $NAVI: $('#navi'),
      $CONTAINER: $('#container'),
      $CONTENT: $('#content'),
      $FOOTER: $('#footer')
    };

    App.mode = {
      device: '',
      pc: 'mode-pc',
      sp: 'mode-sp'
    };

    App.state = {
      ACTIVE: 'is-active',
      HIDDEN: 'is-hidden'
    };
  }

  new Initialize();
};