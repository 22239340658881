'use strict';
/* Basement */
import Initialize from './basement/initialize';
import Observer from './basement/observer';
import PageView from './basement/pageview';

/* Events */
import EventsLoad from './events/load';
import EventsHeader from './events/header';

/* Module */
import ModuleSmoothScroll from './module/smooth-scroll';
import ModuleScrollAnimation from './module/scroll-animation';
import ModuleScrollFollow from './module/scroll-follow';
import ModuleVoiceSlider from './module/voice-slider';
import ModuleLoopSlider from './module/loop-slider';
import ModuleTab from './module/tab';

import ModuleHero from './module/hero';

///////////////////////////////////////////////////////////
// Document Ready
//
$(function(){
  Observer();
  Initialize();
  PageView();

  EventsLoad();
  EventsHeader();

  ModuleSmoothScroll();
  ModuleScrollAnimation();
  ModuleScrollFollow();
  ModuleVoiceSlider();
  ModuleLoopSlider();
  ModuleTab();

  ModuleHero();
});
