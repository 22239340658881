import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default () => {
  const p = ModuleScrollAnimation.prototype;

  function ModuleScrollAnimation(){
    this._$fadeIn = App.elems.$CONTENT.find('[data-animate="fadein"]');
    this._$zoomOut = App.elems.$CONTENT.find('[data-animate="zoomout"]');

    this.handleEvents();
  }

  p.handleEvents = function(){
    Observer.on(App.events.OPENED, this.onOpened.bind(this));
  };

  p.onOpened = function(page){
    this.fadeIn();
    this.zoomOut();
  };

  p.fadeIn = function(){
    ScrollTrigger.batch(this._$fadeIn, {
      onEnter: batch => gsap.to(batch, {
        stagger: 0.1,
        duration: 2,
        ease: 'power3.out',
        opacity: 1,
        y: 0
      }),
      start: 'top 70%',
      once: true
    });
  };

  p.zoomOut = function(){
    ScrollTrigger.batch(this._$zoomOut, {
      onEnter: batch => gsap.to(batch, {
        stagger: 0.1,
        duration: 2,
        ease: 'power3.out',
        opacity: 1,
        scale: 1
      }),
      start: 'top 70%',
      once: true
    });
  };

  new ModuleScrollAnimation();
};