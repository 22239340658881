export default () => {
  const p = Observer.prototype;

  function Observer() {
    this._listeners = {};
  }

  p.on = function(event, func) {
    if(! this._listeners[event]){
    this._listeners[event] = [];
    }
    this._listeners[event].push(func);
  };

  p.off = function(event, func) {
    let ref = this._listeners[event],
        len = ref.length;
    for(let i = 0; i < len; i++){
      let listener = ref[i];
      if(listener === func){
        ref.splice(i, 1);
      }
    }
  };

  p.trigger = function(event, args) {
    let len = (this._listeners[event]) ? this._listeners[event].length : 0;
    for(let i = 0; i < len; i++){
      this._listeners[event][i](args);
    }
  };

  window.Observer = new Observer();
};
