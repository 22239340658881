import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

export default () => {
  const p = ModuleSmoothScroll.prototype;

  function ModuleSmoothScroll(){
    this._$header = App.elems.$HEADER.find('[data-scroll-header]');
    this._$trigger = $('[data-scroll]');

    this._props = {
      duration: 1.2,
      ease: 'power2.out'
    };

    this.handleEvents();
  }

  p.handleEvents = function(){
    this._$trigger.on(App.events.CLICK, this.onClick.bind(this));
  };

  p.onClick = function(e){
    e.preventDefault();
    if(App.mode.device === 'isSP') Observer.trigger(App.events.CLOSE);

    const $trigger = $(e.currentTarget);
    const offset = $($trigger.attr('href')).offset().top;

    gsap.to(App.elems.$HTML_BODY, {
      duration: this._props.duration,
      ease: this._props.ease,
      scrollTo: {y: offset, autoKill: false}
    });
  };

  new ModuleSmoothScroll();
};