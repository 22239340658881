import Swiper from 'swiper/bundle';

export default () => {
  const p = ModuleVoiceSlider.prototype;

  function ModuleVoiceSlider(){
    this._$slider = App.elems.$CONTENT.find('[data-voice-slider]');

    this._props = {
      slider: []
    };

    this.init();
  }

  p.init = function(){
    this.onModeChange();
    this.handleEvents();
  };

  p.handleEvents = function(){
    Observer.on(App.events.MODECHANGE, this.onModeChange.bind(this));
  };

  p.onModeChange = function(mode){
    if(this._$slider.length){
      if(App.mode.device === 'isPC'){
        this._$slider.each(this.setPC.bind(this));
      }else{
        this._$slider.each(this.setSP.bind(this));
      }
    }
  };

  p.setPC = function(idx, val){
    this.destroy(idx);
    this._props.slider[idx] = new Swiper(val, {
      direction: 'vertical',
      loop: true,
      slidesPerView: 'auto',
      speed: 15000,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
        reverseDirection: (idx % 2 === 0) ? true : false
      }
    });
  };

  p.setSP = function(idx, val){
    this.destroy(idx);
    this._props.slider[idx] = new Swiper(val, {
      loop: true,
      slidesPerView: 'auto',
      speed: 15000,
      autoplay: {
        delay: 0,
        disableOnInteraction: false
      }
    });
  };

  p.destroy = function(idx){
    if(this._props.slider[idx]){
      this._props.slider[idx].destroy();
      this._props.slider[idx] = null;
    }
  };

  new ModuleVoiceSlider();
};