export default () => {
  const p = PageView.prototype;

  function PageView() {
    this._props = {
			timer: null,
			interval: 50,
			currentMode: '',
      time: Date.now()
		};
		this._window = {};
    this._scroll = {};
    
		this.init();
  }

  p.init = function() {
    this.checkMode();
		this.handleEvents();
  };

  p.handleEvents = function() {
    App.elems.$WIN.on(App.events.RESIZE, this.onResize.bind(this));
    App.elems.$WIN.on(App.events.SCROLL, this.onScroll.bind(this));
  };

  p.onResize = function() {
    clearTimeout(this._props.timer);
		this._props.timer = setTimeout(this.setResizeInterval.bind(this), this._props.interval);
  };
  
  p.onScroll = function(e){
    this._scroll.y = App.elems.$DOC.scrollTop();
		Observer.trigger(App.events.SCROLL, this._scroll);
  };

  p.setResizeInterval = function() {
    this._window.w = App.elems.$WIN.innerWidth();
		this._window.h = App.elems.$WIN.innerHeight();
		this.checkMode();
		Observer.trigger(App.events.RESIZE, this._window);
  };

  p.checkMode = function() {
    this._props.mode = App.elems.$HEAD.css('font-family').replace(/"/gi, '');
		
		if(this._props.currentMode !== this._props.mode){
			App.mode.device = (this._props.mode == 'pc') ? 'isPC' : 'isSP';
			
			if(this._props.currentMode){
				App.elems.$HTML.removeClass(App.mode[this._props.currentMode]);
			}
			App.elems.$HTML.addClass(App.mode[this._props.mode]);
			this._props.currentMode = this._props.mode;
			
			Observer.trigger(App.events.MODECHANGE, this._props.mode);
		}
  };

  new PageView();
};