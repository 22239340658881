import { gsap } from 'gsap';

export default () => {
  const p = ModuleHero.prototype;

  function ModuleHero(){
    this._$hero = App.elems.$CONTENT.find('#hero');

    this._props = {
      y: App.elems.$DOC.scrollTop()
    };

    this.init();
  }

  p.init = function(){
    if(this._$hero.length) this.handleEvents();
  };

  p.handleEvents = function(){
    Observer.on(App.events.LOADED, this.onLoaded.bind(this));
    Observer.on(App.events.OPENED, this.onOpened.bind(this));
  };

  p.onLoaded = function(){
    this._$video = this._$hero.find('[data-hero-video]');
    this._props.video = this._$video.attr('data-hero-video');

    Observer.on(App.events.MODECHANGE, this.onModeChange.bind(this));
    Observer.on(App.events.SCROLL, this.onScroll.bind(this));
    this.onModeChange();
    this.onScroll(this._props);
  };

  p.onOpened = function(page){
    this._$image = this._$hero.find('[data-hero-image]');
    this._$title = this._$hero.find('[data-hero-title] span');
    this._$news = this._$hero.find('[data-hero-news]');
    this._$scroll = this._$hero.find('[data-hero-scroll]');

    this.animate();
  };

  p.onModeChange = function(){
    if(App.mode.device === 'isPC'){
      this._$video.attr('src', `${this._props.video}_pc.mp4`);
    }else{
      this._$video.attr('src', `${this._props.video}_sp.mp4`);
    }
  };

  p.onScroll = function(e){
    gsap.set(this._$video, {
      marginTop: e.y / 5
    });
  };

  p.animate = function(){
    gsap.to(this._$image, {
      duration: 3.2,
      ease: 'power2.out',
      opacity: 1,
      scale: 1
    });
    gsap.to(this._$title, {
      delay: 0.2,
      stagger: 0.05,
      duration: 1.6,
      ease: 'back.out(2)',
      opacity: 1,
      y: 0
    });
    gsap.to(this._$news, {
      delay: 1.6,
      duration: 1.2,
      ease: 'power2.out',
      opacity: 1
    });
    gsap.to(this._$scroll, {
      delay: 1.6,
      duration: 1.2,
      ease: 'power2.out',
      opacity: 1
    });
  };

  new ModuleHero();
};